<template>
  <div class="particulars">
    <Ytx_header />
    <div class="particulars-center">
      <div class="par-mid">
        <div class="wrap_content">
          <div class="wcon" v-for="(jtem, j) in comTeamHonorList" :key="j">
            <div class="title intotitle" v-if="jtem.userList.length > 0">
              <h2>{{ jtem.certType }}</h2>
            </div>
            <ul>
              <li
                v-for="(item, i) in jtem.userList"
                :key="i"
                class="container_row_selfthing"
              >
                <div class="item-img">
                  <el-image
                    :src="item.standardAvatarOrigUrl"
                    alt=""
                    :onerror="defaultImg"
                  />
                  <div class="goodslook">
                    <b class="honorname">{{ item.userName }}</b>
                  </div>
                  <div class="product-title">
                    <span>{{ item.professionName }}</span>
                  </div>
                </div>
              </li>
            </ul>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <!-- 头部结束 -->
      <div class="page">
        <el-pagination
          @size-change="handlecustomSizeChange"
          background
          @current-change="handlecustomCurrentChange"
          :current-page="pageIndex"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </div>
    <Ytx_footer />
  </div>
</template>
<script>
import {
  companygetcompanyinfo,
  getcompanyteamhonorlist
} from '@/api/company.js';
import Ytx_header from '@/components/ytx_header.vue';
import Ytx_footer from '@/components/ytx_footer.vue';

export default {
  components: {
    Ytx_header,
    Ytx_footer
  },
  data() {
    return {
      isfromoa: false,
      comCertList: [],
      comHonorList: [],
      comElegantList: [],
      productlist: [],
      //   customproductlist: [],
      a: 1,
      activeName: 'first',
      productactiveName: 'first',
      cid: '',
      isloding: false,
      info: {},
      cmpinfo: {},
      isskip: 1,
      OfficeVisible: false,
      tableData: [],
      isInquriy: 1,
      type: '',
      pageIndex: 1,
      pageSize: 12,
      totalCount: 0,
      custompageIndex: 1,
      custompageSize: 12,
      customtotalCount: 0,
      comTeamHonorList: [],
      defaultImg:
        'this.src="' + require('@/assets/img/defaultheadpic.png') + '"'
    };
  },
  methods: {
    handlecustomCurrentChange(item) {
      this.pageIndex = item;
      this.ongetcompanyteamhonorlist();
    },
    handlecustomSizeChange(item) {
      this.pageSize = item;
      this.ongetcompanyteamhonorlist();
    },
    oncompanygetcompanyinfo() {
      companygetcompanyinfo({ companyid: this.cid }).then(res => {
        console.log(res);
        this.cmpinfo = res.data.result;
        for (let i = 0; i < res.data.result.comHonorList.length; i++) {
          this.comHonorList.push(res.data.result.comHonorList[i].imgOUrl);
        }
        for (let i = 0; i < res.data.result.comElegantList.length; i++) {
          this.comElegantList.push(res.data.result.comElegantList[i].imgOUrl);
        }
        for (let i = 0; i < res.data.result.comCertList.length; i++) {
          this.comCertList.push(res.data.result.comCertList[i].imgOUrl);
        }

        this.comTeamHonorList = this.cmpinfo.comTeamHonorList;
        this.totalCount = this.comTeamHonorList.length;
      });
    },
    ongetcompanyteamhonorlist() {
      let params = {
        companyId: this.cid,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      };
      getcompanyteamhonorlist(params).then(res => {
        if (res.data.code) {
          this.comTeamHonorList = res.data.result;
          //this.totalCount = res.data.result.totalCount;
        }
      });
    }
  },
  mounted() {
    var id = this.$route.query.id;
    this.isfromoa = this.$route.query.fromoa == '1';
    this.cid = id;
    this.isInquriy = this.$route.query.isInquriy;
    this.info = {
      cmp: {},
      cert: {}
    };
    this.isloding = true;
    this.oncompanygetcompanyinfo();
    this.ongetcompanyteamhonorlist();
  }
};
</script>
<style scoped>
.particulars {
  width: 100%;
  background: #fff;
}

.particulars-center {
  width: 100%;
  min-width: 1280px;
}
.particulars-center >>> .el-tabs__header {
  margin-bottom: 0;
}

.product {
  max-width: 1200px;
  margin: 0 auto;
}
.product .product-content {
  width: 1200px;
  min-width: 1200px;
  background: #fff;
  margin: auto;
}
.product .store-content {
  width: 100%;
  display: flex;
}

.clearfix {
  clear: both;
}
.page {
  margin-top: 30px;
  text-align: center;
}
.par-mid {
  max-width: 1200px;
  margin: 0 auto;
}
.par-mid .wrap_content {
  /* border: 1px solid #eee; */
  margin: 80px 0;
  min-height: 180px;
}
.par-mid .wrap_content h3 {
  padding: 10px;
}
.wcon ul li {
  float: left;
  cursor: pointer;
}

.wcon .item-img {
  width: 180px;
  height: 260px;
  text-align: center;
  margin: 5px 16px;
}
.wcon .item-img img {
  width: 160px;
  height: 180px;
  border-radius: 40px 0 40px 0;
}
.wcon .product-title span {
  line-height: 20px;
  font-size: 13px;
  color: #707070;
}
.wcon .honorname {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #222222;
}
.title {
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.title h2 {
  display: inline-block;
  font-family: PingFangSC-Semibold, Microsoft YaHei;
  font-size: 14px;
  color: #333;
  line-height: 32px;
  font-weight: 600;
  position: relative;
  padding-left: 10px;
}
.title.intotitle h2::before {
  content: '';
  position: absolute;
  width: 3px;
  height: 19px;
  border-radius: 2px;
  background-color: #268eff;
  left: 0;
  top: 8px;
}
</style>
